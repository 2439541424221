* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
  }
  
.pageContainer{
  position: relative;
  min-height: 100vh;
}

.contentWrap{
  padding-bottom: 15rem;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3 ease-out;
}

.btn--cover{
  background-color: transparent;
  color: #fff;
  border: 1px solid var(--primary);
  font-size: 50px;
  transition: .5s;
}

.btn--cover:hover{
  background-color:#fff;
  color: black;
  
}

.btn-medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn-large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn-large:hover{
    background: #fff;
    color: #242424;
    transition: all 0.3 ease-out;
}

.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #0c2e53;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
  }

  .cardSpan{
    font-weight: bolder;
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }

    .counties{
      grid-template-columns: repeat(1,1fr);
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  .contact-container{
    width:100%;
    background-color: #fff;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

  #contactPageForm{
    display: flex;
    flex-direction: column;
    position: relative;
    right: 0%;
    border: #0c2e53 3px solid;
    background-color: #ebebeb;
    margin-bottom: 10px;
    padding-bottom: 10px;
    max-width: 1000px;
  }

  .socials{
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    max-width: 1000px;
  }

  .socials div{
    flex: 1 1 auto;
  }

  .contactDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .contactDiv img{
    width: auto;
    border: #0c2e53 3px solid;
    margin-bottom: 10px;
    max-height: 500px;
  }

  .contactDiv form{
    width: 80%;
  }

  .contactQueries{
    display: flex;
    flex-direction: row;
  }

.contact-subscription > p{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.contact-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
}

.contact-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
}

.contact-input{
    align-self: center;
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid rgb(0, 0, 0);
}

.contact-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color:rgb(0, 0, 0)
}

.footer-container{
    background-color: #0c2e53;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 3rem;
    width: 100%;
    height: 12rem;
  
}

.footer-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color:#fff
}

.footer-subscription > p{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input{
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footerLinks{
    margin-top: 20px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footerLinks *{
  margin: 0 5px;
}

.footer-link-wrapper{
    display: flex;
}

.footer-link-items{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2{
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover{
    color: #e9e9e9;
    transition: 0.3 ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::placeholder{
    color: #b1b1b1;
}

.social-icon-link{
    color: #fff;
    font-size: 24px;
}

.social-media{
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.socialIcons{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
}


.socialIcons i {
  padding: 20px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ebebeb;
  transition: 0.2s ease;
  cursor: pointer;
}

.socialIcons svg{
  color: #0c2e53;
  transition: 0.2s ease;
  height: 2rem;
}

.socialIcons i:hover{
  background-color: white;
}

.socialIcons i:hover svg{
  color: #ebebeb;
}

.vl{
  border-left: 1px solid #69beff;
  height: 100%;
}

.pageButtonContainer{
  display: flex;
  justify-content: center;
  margin: 10px;
}

.pageButton{
  color: #0c2e53;
  cursor: pointer;
  margin: 0 5px;
}

.disabled{
  pointer-events: none;
  color: #ebebeb;
  margin: 0 5px;

}



@media screen and (max-width: 820px) {
    .footer-links{
        padding-top: 2rem;
    }
    .footer-input{
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper{
        flex-direction: column;
    }

    .social-media-wrap{
        flex-direction: column;
    }

    .listingSearchQueries{
      flex-direction: column !important;
    }

    .About-container{
      flex-direction: column;
    }

    .About-container img{
      width: 100% !important;
    }

    .listingBot{
      flex-direction: column !important;
      width: auto !important;
   }

   .listingContact{
    position: relative !important;
    width: 50% !important;
    min-width: 350px;
    right: 0% !important;
   }

   .listingDescription{
    width: auto !important;
   }

   #contactPageForm{
      min-width: 90%;
   }

   .contactDiv{
    flex-direction: column;
  }

  .contactDiv img{
    max-height: 360px;
    margin: 10px auto;
  }

  .contactDiv form{
    min-width: 600px;
  }

  .socials{
    flex-direction: column;
  }

  .contactQueries{
    flex-direction: column;
  }
}

 video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position:fixed;
    z-index: -1;
} 

.platBG{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./images/Platinum.webp');
}

.parallaxHome{
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
  padding-bottom: 10px;
}

.aboutHero{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.aboutHero img{
  position: relative;
  object-fit: cover;
  height: 800px;
  width: 100%;
  filter: brightness(0.8);
}

.aboutHero h1{
  position: absolute;
  left: 0;
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: italic;
  width: 100%;

}

.hero > h1 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  margin-top: 30px;
  font-style: italic;
}

.hero > p {
  margin-top: 8px;
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-bottom: 50px;
  text-align: center;
}

.listingPageContainer{
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
}

.listingBot{
  display: flex;
  margin: 10px;
  flex-direction: row;
  width: 100%;
}

.carouselImg{
  height: 100%;
  width: 100%;
  object-fit: fill;
  border: #0c2e53 3px solid;
}

.react-multi-carousel-list {
  max-height: 600px;
}

.react-multi-carousel-track{
  max-height: 600px;
}

.react-multiple-carousel__arrow{
  background-color: #0c2e53;
}

.react-multiple-carousel__arrow--right {
  z-index: 1;
}

.react-multiple-carousel__arrow--left {
  z-index: 1;
}

.react-multi-carousel-dot button{
  margin-bottom: 10px;
}

.react-multi-carousel-dot-list {
  padding: 10px 0px !important;
}

.next{
  right: 1rem;
}

.prev{
  left: 1rem;
}

.listingDescription{
  width: 70%;
  text-align: left;
  margin-left: 10px;
  margin-top: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  justify-content: space-between;
  border: #0c2e53 solid 2px;
}

.listingDescriptionTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0px;
}

.listingDescriptionDetails{
  display: flex;
  justify-content: space-between;
}

.listingDetail{
  display: flex;
  justify-content: space-between;
}

.spacer{
  flex-grow: 1;
  height: 2px;
  margin: 10px 10px;
  border-top: 2px dotted #747474;
}

.listingDescription h1{
  color: #0c2e53;
  text-align: left;
}

.listingDescription h2{
  font-size: 1.5rem
}

.listingDescription h3{
  color: #0c2e53;
  margin-bottom: 20px;
}

#listingCityCode{
  color: rgba(116,116,116,1);
}

.listingDescription h3 span{
  color: black;
  font-size: 1.5rem;
}

.listingDescription p{
  color:rgb(34, 34, 34);
  line-height: 150%;
  font-size: 18px;
}

.statWithIcon{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 0.6 1 0px;
}

.statWithIcon div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1.5 1 0px;
  white-space: nowrap;
}

.statWithIcon h3{
  margin: 0;
}

.statWithIcon svg{
  height: 2rem;
  color: #0c2e53;
}

.botLeft{
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.botRight{
  padding: 12px;
  width: 50%;
}

.appList{
  list-style: none;
  padding-left: 12px;
}

.listingSearch{
  display: flex;
  flex-direction: row;
}

.listingContact{
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  border: 1px solid grey;
  width: 20%;
  z-index: 99;
  margin-top: 20px auto 0;
  position: fixed;
  right: 4%;
  bottom: 0.5%;
  height: 50%;
}

.listingContact h1{
  border-bottom: 2px solid grey;
  background-color: #0c2e53;
  color: #fff;
  border-radius: 8px 8px 0 0;
  margin-bottom: 5px;
}

.listingContact * {
  flex: 1 1 auto;
}

.listingContact input, .listingContact textarea{
  font-size: 16px;
  line-height: 20px;
  width: 80%;
  background-color: rgb(246, 246, 250);
  border-radius: 4px;
  border: 1px solid grey;
  transition: 0.2s ease
}

.listingContact input:hover, .listingContact textarea:hover, .listingContact input:focus, .listingContact textarea:focus{
  border-color: #69beff;
}


.listingContact button{
  font-size: 24px;
  background-color: #0c2e53;
  color: white;
  width: 80%;
  border-radius: 4px;
  margin: 0 auto;
}

.listingContact img{
  border-radius: 50%;
  width: 55%;
  height: auto;
  margin: 10px auto;
}

.valid{
  display: none;
}

.invalid{
  display: block;
  color: red;
  width: 80%;
}


@media screen and (max-width: 768px) {
    .btn-mobile{
        display: block;
        text-decoration: none;
    }

    .btn{
        width: 100%;
    }
}

.tagLine{
  flex: 1;
  color: white;
  font-style: italic;
}

.navbar {
    background: #0c2e53;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    height: auto;
  }
  
  .navbar-container {
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
  }
  
  .navbar-logo {
    flex: 1;
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
  }

  @media screen and (max-width: 654px){
    .nav-menu{
      top: 96px !important;
    }
  }

  @media screen and (max-width: 498px){
    .nav-menu{
      top: 128px !important;
    }
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars, .fa-xmark {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    flex: 1;
    display: none;
  }

  .featured{
    padding-top: 15px;
    background-color: white;
  }

  .featured h1{
    margin-bottom: 15px;
  }

  .featuredHomes{
    display: grid;
    justify-content: center;
    grid-template-columns: 300px 300px 300px 300px;
    margin: 0 auto;
  }

  .counties{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 0px auto;
  }

  .county{
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .county .cards__item__pic-wrap::after {
    margin: 0 auto;
    background-color: #0c2e53e5;
  }

  .county h1{
    position: absolute;
    left: 50%;
    bottom: 10%;
  }

  @media screen and (max-width: 1200px) {
    .featuredHomes{
      grid-template-columns: 300px 300px 300px;
    }
  }

  @media screen and (max-width: 900px) {
    .featuredHomes, .counties{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 600px) {
    .featuredHomes{
      grid-template-columns: 1fr;
    }

    .counties{
      grid-template-columns: 1fr;
    }
  }

  .featuredHomes > *{
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      flex: 1;
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      color: #fff;
      justify-self: start;
      margin-left: 20px;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
    }
  
    .menu-icon {
      flex: 1;
      display: flex;
      justify-content: end;
      /* transform: translate(-100%, 60%); */
      font-size: 1.8rem;
      cursor: pointer;
      margin-right: 20px;
    }

    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

    .featuredHomes > * {
      flex-basis: 34%;
    }

    .About-text-container img{
      width: 100%;
    }
  }

  @media ( max-width: 600px) {
    .featuredHomes > * {
      flex-basis: 51%;
    }
  }

  .formC{
    width:100%;
  }

  .rows{
    height:100%;
    width:100%;
    display:block;
  
  }
  .column{
      margin: auto;
      width:30%;
      display:inline-block;
      
  }

  .headshot{
    border-radius: 50%;
    border: #fff;
    width: 400px;
    height: 400px;
    border: 5px solid #fff;
  }

  .About-container{
    justify-content: center;
    max-width: 80%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    padding: 40px 0;
  }

  .About-container img{
    width: 40%;
    height: auto;
    border: #69beff 3px solid;
    max-height: 500px;
  }

  .About-text{
    max-width: 90%;
    height: auto;
    font-size: 18px;
    line-height: 2;
    color: black;
  }

  .About-text-container{
    position: relative;
    text-align: center;
    justify-content: space-evenly;
    max-width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .About-text-container h1{
    font-size: 60px;
  }

  .reviewCarousel{
    width: 90%;
    border-radius: 10px;
    background-color: #ebebeb;
    border: 1px solid #0c2e53;
    margin: 0 auto;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }

  .review{
    width: 85%;
    padding: 20px;
    margin: 0 auto;
    color: black;
    text-align: center;
  }
  
  .review h1{
    font-size: 48px;
    color: #0c2e53;
    border-bottom: 1px dashed #0c2e53;
  }

  .review p{
    font-size: 18px;
    color: black;

  }

  .loc-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 90%;
    margin:auto;
    flex-wrap: wrap;
  }

  .loc{
    max-width: 100%;
    height: auto;
    text-align: center;
    flex-basis: 33.333%;
  }

  .loc > h2{
   color: gray;
  }

  .loc > h3{
    color: #0c2e53;
  }

  .header_search{
    display: flex;
    justify-content: space-around;
  }

  .header_search h1{
    border-bottom: 2px solid #0c2e53;
  }

  .searchSection{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px;
  }

  .listingSearch{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .listingSearchQueries{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
  }

  .listingSearchQueries div {
    width: 100%;
    flex: 1 1 auto;
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .listingSearchQueries div * {
    width: 100%;
    text-align: center;
  }

  .listingSearchQueries select, .listingSearchQueries input {
    padding: 5px;
  }

  .searchButton button {
    color: white;
    background-color: #242222;
    width: 15rem;
    cursor: pointer;
  }

  .searchSection div label{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .blueBG{
    background-color: #0c2e53;
    color: white;
  }

  .homeCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 50px 0;
    width: 75%;
    font-size: 20px;
  }

  .homeCard {
    width: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    text-align: center;
  }

  .searchButtons{
    display: flex;
    width: 500px;
  }

  @media screen and (max-width: 560px){
    .searchButtons{
      width: 100% !important;
    }
  }

  .searchButtons button{
    flex: 1 1 0px;
    padding: 5px;
    transition: 0.2s ease;
    cursor: pointer;
  }

  .residential{
    margin-right: 10px;
  }

  .land{
    margin-left: 10px;
  }

  .searchButtons .chosen{
    background-color: #242222;
    color: white;
  }

  .searchButtons .unChosen{
    background-color: #ebebeb;
  }

  .addSubtract{
    margin: 0 !important;
    margin-top: 10px !important;
  }

  .aboutIntro{
    font-size: 24px;
    line-height: 2;
    color: #242424;
    padding: 0 50px;
    text-align: center;
    background-color: #ebebeb;
    border-bottom: 1px solid #0c2e53;
  }

  .contactQueries input, .contactQueries textarea{
    width: 95%;
  }

  .sousaFooter{
    height: 3rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: skyblue;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.sousaFooter *{
    text-align: center;
}

.policyLinks{
    display: flex;
    justify-content: space-evenly;
}



  
